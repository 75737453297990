import axios from 'axios'
import token from '../pages/HomePage'

var api = axios.create({
    baseURL: 'http://awscraft.cloud.nvias.org:8000',
    headers: {'Authorization': "Bearer " + localStorage.getItem('token')}
    /*baseURL: 'http://localhost:8000',*/
})

export const updateToken = () => {
    api = axios.create({
        baseURL: 'http://awscraft.cloud.nvias.org:8000',
        headers: {'Authorization': "Bearer " + localStorage.getItem('token')}
        /*baseURL: 'http://localhost:8000',*/
    })
}

export const logIn = payload => api.post(`/token`, payload)
export const createAwsInstance = data => api.post(`/aws/launch`, data)
export const getAwsInstances = () => api.get(`/aws/instances`)
export const installAwsInstance = (aws_id, payload) => api.post(`/aws/instances/${aws_id}/install`, payload)
export const startAwsInstance = (aws_id, payload) => api.post(`/aws/instances/${aws_id}/aws_start`, payload)
export const stopAwsInstance = (aws_id, payload) => api.post(`/aws/instances/${aws_id}/aws_stop`, payload)
export const getPterodactylUrl = (aws_id, payload) => api.get(`/aws/instances/${aws_id}/pterodactyl`)
export const getServerTemplates = () => api.get(`/server_templates`)
/*
export const updateMovieById = (id, payload) => api.put(`/movie/${id}`, payload)
export const deleteMovieById = id => api.delete(`/movie/${id}`)
export const getMovieById = id => api.get(`/movie/${id}`)*/

const apis = {
    logIn,
    createAwsInstance,
    getAwsInstances,
    installAwsInstance,
    startAwsInstance,
    stopAwsInstance,
    getPterodactylUrl,
    getServerTemplates,
    updateToken,
    /*insertMovie,
    getAllMovies,
    updateMovieById,
    deleteMovieById,
    getMovieById,*/
}

export default apis