import React from "react";
import api from '../api'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faStop, faAngleDown, faAngleUp, faArrowDown, faArrowUp, faEdit, faEllipsisH, faExternalLinkAlt, faEye, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Nav, Card, Image, Button, Table, Dropdown, ProgressBar, Pagination, ButtonGroup, Form, Badge} from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';
import Preloader from "../components/Preloader";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

//import servers from "../data/servers";
import { react } from "@babel/types";

const MySwal = withReactContent(Swal)

const Toast = MySwal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})

//export const AWSCraftOverviewTable = () => {


  class StartServerButton extends React.Component {
    startServer = event => {
      api.startAwsInstance(this.props.id).then(async response => {
        await Toast.fire({
          icon: 'success',
          title: response.data
        })
      }).catch(error => {
        if (error.response) {
          let errormsg = ""
          if (typeof(error.response.data.detail) === "object") {
            errormsg = JSON.stringify(error.response.data.detail)
          } else {
            errormsg = error.response.data.detail
          }
          MySwal.fire("Error starting server", errormsg, "error")
        }
      });
    }
    render() {
      return (
        <Button onClick={this.startServer} size="sm" className="text-success" variant="link"><FontAwesomeIcon icon={faPlay} /></Button>
      );
    }
  }


class StartServerButtonContext extends React.Component {
  startServer = event => {
    api.startAwsInstance(this.props.id).then(async response => {
      await Toast.fire({
        icon: 'success',
        title: response.data
      })
    }).catch(error => {
      if (error.response) {
        let errormsg = ""
        if (typeof(error.response.data.detail) === "object") {
          errormsg = JSON.stringify(error.response.data.detail)
        } else {
          errormsg = error.response.data.detail
        }
        MySwal.fire("Error starting server", errormsg, "error")
      }
    });
  }
  render() {
    return (
      <Dropdown.Item onClick={this.startServer}>
      <FontAwesomeIcon icon={faPlay} className="me-2" /> Start
      </Dropdown.Item>
    );
  }
}

class StopServerButton extends React.Component {
  stopServer = event => {
    api.stopAwsInstance(this.props.id).then(async response => {
      await Toast.fire({
        icon: 'success',
        title: response.data
      })
    }).catch(error => {
      if (error.response) {
        let errormsg = ""
        if (typeof(error.response.data.detail) === "object") {
          errormsg = JSON.stringify(error.response.data.detail)
        } else {
          errormsg = error.response.data.detail
        }
        MySwal.fire("Error stopping server", errormsg, "error")
      }
    });
  }
  render() {
    return (
      <Button onClick={this.stopServer} size="sm" className="text-danger" variant="link"><FontAwesomeIcon icon={faStop} /></Button>
    );
  }
}


class StopServerButtonContext extends React.Component {
  stopServer = event => {
    api.stopAwsInstance(this.props.id).then(async response => {
      await Toast.fire({
        icon: 'success',
        title: response.data
      })
    }).catch(error => {
      if (error.response) {
        let errormsg = ""
        if (typeof(error.response.data.detail) === "object") {
          errormsg = JSON.stringify(error.response.data.detail)
        } else {
          errormsg = error.response.data.detail
        }
        MySwal.fire("Error stopping server", errormsg, "error")
      }
    });
  }
  render() {
    return (
      <Dropdown.Item onClick={this.stopServer}>
      <FontAwesomeIcon icon={faStop} className="me-2" /> Stop
      </Dropdown.Item>
    );
  }
}

class OpenPterodactylButton extends React.Component {
  openPterodactyl = event => {
    api.getPterodactylUrl(this.props.id).then(response => {
      var { url } = response.data
      window.open(url, "_blank")
    })
  }
  render() {
    return (
      <Dropdown.Item onClick={this.openPterodactyl}>
      <FontAwesomeIcon icon={faExternalLinkAlt} className="me-2" /> Console
      </Dropdown.Item>
    );
  }
}


class TemplatesDropDown extends React.Component {
  state = {
      values: []
  }
  componentDidMount() {
     api.getServerTemplates()
      .then(res => {
          return res.data;
      }).then(json => {
          this.setState({
             values: json
          })
      });
  }
  render(){
      return  <Form.Select name="template_id" className="me-sm-2" id="inlineFormCustomSelect">{
               this.state.values.map((template) => {
                 if (template.enabled){
                  return <option value={template.id}>{template.template_name}</option>
                 }
               })
            }</Form.Select>;
  }
}


export class AWSCraftOverviewTable extends React.Component  {


  constructor(props) {
    super(props)
    this.state = {
      servers: [],
      isLoading: false,
    }
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event) {
    event.preventDefault();
    const form = event.currentTarget;
    console.log(form)
    const payload = {name: form.elements.server_name.value, ec2_type: form.elements.ec2_type.value, volume_size: parseInt(form.elements.volume_size.value), template_id: parseInt(form.elements.template_id.value)};
    api.createAwsInstance(payload).then(response => {
      Toast.fire({
        icon: 'success',
        title: response.data
      })
    }).catch(error => {
      if (error.response.status === 422) {
        let errormsg = ""
        if (typeof(error.response.data.detail) === "object") {
          errormsg = JSON.stringify(error.response.data.detail)
        } else {
          errormsg = error.response.data.detail
        }
        MySwal.fire("Error creating server", errormsg, "error")
      }
    });
  };

  componentDidMount = async () => {
    this.setState({ isLoading: true })

    /*await api.getAwsInstances().then(instances => {
      this.setState({
        servers: instances.data,
        isLoading: false,
      })
    })*/

    var prevServers = ""

    const updateTable = async () => {
      await api.getAwsInstances().then(instances => {
        const serversdata = instances.data
        let servers = []
        //console.log(servers)
        serversdata.forEach((item, index, arr) => {
          if (item.api_version === 2 || 1===1){
            servers.push(item)
          }
        })
        const serversStr = JSON.stringify(servers)
        if (serversStr !== prevServers){
          this.setState({
            servers: servers,
            isLoading: false,
          })
          prevServers = serversStr;
        }
      })
      setTimeout(updateTable, 5000)
    }

    updateTable();

    //auto update
    //this.interval = setInterval(async () => {
    //    updateTable();
    //}, 5000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {

    const { servers, isLoading } = this.state
    //const totalServers = servers.length;
    const totalServers = Object.keys(servers).length;
  
    const TableRow = (props) => {
      var { aws_id, manager_id, name, aws_status, status, server_status, address, api_version } = props;

      const instanceStatus = aws_status;
      const serverStatus = status;

      const instanceStatusText = instanceStatus === 0 ? "Starting"
      : instanceStatus === 16 ? "Running"
      : instanceStatus === 32 ? "Shutting down"
      : instanceStatus === 48 ? "Terminated"
      : instanceStatus === 64 ? "Stopping"
      : instanceStatus === 80 ? "Stopped"
      : "Unknown";

      const instanceStatusVariant = instanceStatusText === "Running" ? "success"
      : instanceStatusText === "Starting" ? "warning"
      : instanceStatusText === "Stopping" ? "warning"
      : instanceStatusText === "Shutting down" ? "warning"
      : instanceStatusText === "Stopped" ? "danger"
      : "primary";

      const serverStatusText = serverStatus === 0 ? "Unknown"
      : serverStatus === 1 ? "Creating..."
      : serverStatus === 2 ? "Starting machine..."
      : serverStatus === 3 ? "Installing Minecraft..."
      : serverStatus === 4 ? "Starting Minecraft..."
      : serverStatus === 5 ? "Online"
      : serverStatus === 6 ? "Stopping Minecraft..."
      : serverStatus === 7 ? "Stopping machine..."
      : serverStatus === 8 ? "Stopped"
      : serverStatus === 9 ? "Error"
      : serverStatus === 10 ? "Installation failed"
      : "Undefined";
      const serverStatusVariant = serverStatus === 0 ? "dark"
      : serverStatus === 1 ? "warning"
      : serverStatus === 2 ? "warning"
      : serverStatus === 3 ? "warning"
      : serverStatus === 4 ? "warning"
      : serverStatus === 5 ? "success"
      : serverStatus === 6 ? "warning"
      : serverStatus === 7 ? "warning"
      : serverStatus === 8 ? "danger"
      : "dark";

      if (address === "") {
        address = "Not assigned";
      }
  
      const serverPath = "/aws/" + manager_id

      return (
        <tr className={serverStatusVariant === "danger" ? "" : `table-${serverStatusVariant}`}>
          <td>
            <span className="fw-bold">
              {manager_id}
            </span>
            {/*<Card.Link as={Link} to={"/aws/" + manager_id + "/dashboard"} className="fw-bold">
              {manager_id}
            </Card.Link>*/}
          </td>
          <td>
            <span className="fw-bold">
              {name}
            </span>
          </td>
          <td>
            <span className="fw-bold" onClick={() => {
              navigator.clipboard.writeText(address)
              MySwal.fire({
                position: 'top-end',
                toast: true,
                icon: "success",
                title: "Copied",
                showConfirmButton: false,
                timer: 3000
                })
            }}>
              {address}
            </span>
          </td>
          <td>
            <h6 className="mb-0">
              <Badge pill className="badge-lg fw-bolder" bg={instanceStatusVariant} text={instanceStatusVariant === "warning" ? "dark" : "light"}>{instanceStatusText}</Badge>
            </h6>
          </td>
          <td>
            <h6 className={`mb-0 text text-${serverStatusVariant}`}>
              <Badge pill className="badge-lg fw-bolder" bg={serverStatusVariant} text={serverStatusVariant === "warning" ? "dark" : "light"}>{serverStatusText}</Badge>
            </h6>
          </td>
          {/*<td>
            <span className={`fw-bold text-${serverStatusVariant}`}>
              {serverStatus}
            </span>
          </td>*/}
          <td>
          <ButtonGroup className="rounded-pill" aria-label="Basic example">
            <StartServerButton id={manager_id} />
            <StopServerButton id={manager_id} />
            <Dropdown as={ButtonGroup}>
              <Dropdown.Toggle as={Button} size="sm" variant="link" >
                <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <StartServerButtonContext id={manager_id} />
                <StopServerButtonContext id={manager_id} />
                <OpenPterodactylButton id={manager_id} />
                {/*<Dropdown.Item href="#">
                  <FontAwesomeIcon icon={faEye} className="me-2" /> Manual Assign
                </Dropdown.Item>
                <Dropdown.Item>
                  <FontAwesomeIcon icon={faEye} className="me-2" /> View Details
                </Dropdown.Item>
                <Dropdown.Item>
                  <FontAwesomeIcon icon={faEdit} className="me-2" /> Edit
                </Dropdown.Item>
                <Dropdown.Item className="text-danger">
                  <FontAwesomeIcon icon={faTrashAlt} className="me-2" /> Remove
                </Dropdown.Item>*/}
              </Dropdown.Menu>
            </Dropdown>
          </ButtonGroup>
          </td>
        </tr>
      );
    };
  
    return (
      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Preloader show={isLoading ? true : false} nobg={true} />
        <Card.Body className="pt-0">
          <Table hover className="table-sm user-table align-items-center fw-bold">
            <thead>
              <tr>
                <th className="border-bottom">ID</th>
                <th className="border-bottom">Name</th>
                <th className="border-bottom">Address</th>
                <th className="border-bottom">AWS Status</th>
                <th className="border-bottom">Server Status</th>
                <th className="border-bottom">Action</th>
              </tr>
            </thead>
            <tbody>
              {servers.map(t => <TableRow key={`server-${t.manager_id}`} {...t} />)}
            </tbody>
          </Table>
          <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
            <Form onSubmit={this.handleSubmit}>
              <Row>
                <Form.Group as={Col} controlId="form">
                  <Form.Label>Name</Form.Label>
                  <Form.Control name="server_name" placeholder="24ut_cervena" />
                </Form.Group>
                <Form.Group as={Col} controlId="formGridEmail">
                  <Form.Label>Variant</Form.Label>
                  <Form.Select name="ec2_type" className="me-sm-2" id="inlineFormCustomSelect">
                    <option value="t3.small">Small</option>
                    <option value="t3.medium">Medium</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group as={Col} controlId="formGridEmail">
                  <Form.Label>Disk quota</Form.Label>
                  <Form.Control name="volume_size" placeholder="1" defaultValue="1"/>
                </Form.Group>
                <Form.Group as={Col} controlId="formGridEmail">
                  <Form.Label>Server template</Form.Label>
                  <TemplatesDropDown/>
                </Form.Group>
                <Col>
                  <Form.Label>Create</Form.Label>
                  <br/>
                  <Button type="submit">Create server</Button>
                </Col>
                
              </Row>
            </Form>
            {/*<Nav>
              <Pagination className="mb-2 mb-lg-0">
                <Pagination.Prev>
                  Previous
                </Pagination.Prev>
                <Pagination.Item active>1</Pagination.Item>
                <Pagination.Item>2</Pagination.Item>
                <Pagination.Item>3</Pagination.Item>
                <Pagination.Item>4</Pagination.Item>
                <Pagination.Item>5</Pagination.Item>
                <Pagination.Next>
                  Next
                </Pagination.Next>
              </Pagination>
            </Nav>*/}
            <small className="fw-bold">
              Showing <b>{totalServers}</b> entries
            </small>
          </Card.Footer>
        </Card.Body>
      </Card>
    );
  }
}

//export AWSCraftOverviewTable;