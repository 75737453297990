import { useState } from 'react';
import api from '../api'

export default function useToken() {
  const getToken = () => {
    const userToken = localStorage.getItem('token');
    return userToken
  };

  const [token, setToken] = useState(getToken());

  const saveToken = userToken => {
    localStorage.setItem('token', userToken);
    setToken(userToken);
    api.updateToken();
  };

  return {
    setToken: saveToken,
    token
  }
}