import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

//import './index.css';

// core styles
import "./scss/volt.scss";

// vendor styles
import '@fortawesome/fontawesome-free/css/all.css';
import 'react-datetime/css/react-datetime.css';

import HomePage from './pages/HomePage';
import ScrollToTop from './components/ScrollToTop';

import App from './App'

ReactDOM.render(
  <App />,
  document.getElementById("root")
);
